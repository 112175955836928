import axios from 'axios';

export const subscribe = async (formData: FormData): Promise<any> => {
  const endpoint = `${process.env.GATSBY_API_ROOT_URL}/api/whitepaper`;
  const response = await axios.post(endpoint, formData, {
    headers: {
      'Content-Type': `application/json`,
    },
  });

  return response;
};
