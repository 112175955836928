import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BLOCKS } from '@contentful/rich-text-types';
import InsightsWhitePaperStyled from '@/components/styled/InsightsWhitePaperStyled';
import { IContentfulWhitepaper } from '@/types';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';
import Helpers from '@/utils/helpers';
import { subscribe } from '@/http-request/whitepaper-request';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';

// eslint-disable-next-line no-shadow
enum Form {
  SUCCESS,
  FAILED,
  NORMAL,
}

const ContentfulWhitepaper: React.FC<IContentfulWhitepaper> = ({ data }) => {
  const windowWidth = Helpers.getWindowInnerWidth();
  const laptop = windowWidth <= Helpers.window.breakpoint.desktop - 1;
  const [formState, setFormState] = useState<Form>(Form.NORMAL);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);

    if (formState !== Form.SUCCESS) {
      Promise.all([subscribe(form)])
        .then((res) => {
          if (
            res[0].data?.data?.statusCode &&
            res[0]?.data?.data?.statusCode === 400
          ) {
            setFormState(Form.FAILED);
          } else {
            setFormState(Form.SUCCESS);
          }
        })
        .catch(() => {
          setFormState(Form.FAILED);
        });
    }
  };

  useEffect(() => {
    if (formState === Form.SUCCESS) {
      setTimeout(() => {
        window.open(data.contentfulWhitepaper?.file?.file?.url, `_blank`);
      }, 1000);
    }
  }, [formState]);

  const override = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: ({ content }, _children) => (
        <li>
          <h1>{content[0].content[0].value}</h1>
        </li>
      ),
    },
  };

  const renderForm = () => (
    <>
      <div className="main-details">
        <div className="whitepaper-image">
          <Reveal className="banner">
            <div className="image-container whitepaper-image-container">
              <GatsbyImage
                image={getImage(
                  data.contentfulWhitepaper?.image?.image?.gatsbyImageData,
                )}
                alt={data.contentfulWhitepaper?.image?.image?.title || ``}
              />
            </div>

            <div className="featured-remarks">
              <ContentfulRawToComponent
                raw={data.contentfulWhitepaper?.featuredRemarks?.raw}
                options={override}
              />
            </div>
          </Reveal>
        </div>

        <Reveal className="page-label-container">
          <h1 className="label">WHITEPAPER</h1>
        </Reveal>

        <Reveal className="title-container">
          <h1>{data.contentfulWhitepaper.title}</h1>
        </Reveal>

        <Reveal className="remarks">
          {laptop && (
            <ContentfulRawToComponent
              raw={data.contentfulWhitepaper?.featuredRemarks?.raw}
              options={override}
            />
          )}
          <ContentfulRawToComponent
            raw={data.contentfulWhitepaper?.remarks?.raw}
            options={override}
          />
        </Reveal>
      </div>

      <Reveal className="content">
        <section className="whitepaper-form">
          <form
            onSubmit={onSubmitHandler}
            id="form-whitepaper"
            method="post"
            data-form-type="whitepaper"
            className="container"
          >
            <fieldset>
              <div className="info-row">
                <label htmlFor="name">
                  <span>Name</span>
                  <input id="name" type="text" name="name" required />
                </label>
                <label htmlFor="email">
                  <span>Email</span>
                  <input id="email" type="email" name="email" required />
                </label>

                <label htmlFor="jobTitle">
                  <span>Job Title</span>
                  <input id="jobTitle" type="text" name="jobTitle" required />
                </label>
                <label htmlFor="company">
                  <span>Company Name</span>
                  <input id="company" type="text" name="company" required />
                </label>
              </div>

              <div className="consent-row">
                <label htmlFor="consent">
                  <span>
                    I consent to allow IONA to store and process the personal
                    information submitted above to provide the content
                    requested.
                  </span>
                  <input id="consent" type="checkbox" name="consent" required />
                </label>
                <input
                  id="wpTitle"
                  type="hidden"
                  name="wpTitle"
                  value={`${data.contentfulWhitepaper.title}`}
                />
              </div>

              <div className="policy-row">
                <p>
                  IONA can send you communications. You may unsubscribe these
                  these communications at any time. For more information on how
                  unsubscribe and our privacy practices please review our
                  {` `}
                  <a href="/privacy">Privacy Policy</a>.
                </p>
              </div>

              <div className="submit-button">
                <button type="submit" className="wide send-button">
                  DOWNLOAD THE WHITEPAPER
                </button>
              </div>
            </fieldset>
          </form>
        </section>
      </Reveal>
    </>
  );

  const renderSuccessfullySubscribed = () => (
    <>
      <Reveal className="main-details">
        <div className="title-container">
          <div className="subscribed-message">
            <h2>Thank you!</h2>
          </div>
        </div>
      </Reveal>

      <Reveal className="content">
        <div className="subscribed-guide">
          <h3>Your download should have started.</h3>
        </div>
      </Reveal>
    </>
  );

  return (
    <InsightsWhitePaperStyled>
      {usePageMeta(data.contentfulWhitepaper?.meta)}
      <div className="content single-article-page whitepaper-page">
        <Reveal className={`container ${laptop ? `fadeInUp` : ``}`}>
          <div className="page-title link-title">
            <Link className="link back" to="/notes">
              All notes
            </Link>
          </div>

          <div
            className={`post-container ${
              formState === Form.SUCCESS ? `subscribed` : ``
            }`}
          >
            {formState === Form.SUCCESS
              ? renderSuccessfullySubscribed()
              : renderForm()}
          </div>
        </Reveal>
      </div>
    </InsightsWhitePaperStyled>
  );
};

export const query = graphql`
  query Whitepaper($id: String) {
    contentfulWhitepaper(id: { eq: $id }) {
      title
      slug
      featuredRemarks {
        raw
      }
      remarks {
        raw
      }
      image {
        image {
          gatsbyImageData
        }
      }
      file {
        file {
          url
          contentType
          fileName
          details {
            size
          }
        }
      }
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
  }
`;

export default ContentfulWhitepaper;
